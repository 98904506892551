import { Img } from '@/src/components/foundations/DataDisplays/Img';
import { Link } from '@/src/components/foundations/Utils/Link';

import styles from './styles.module.scss';

export const GlobalHeaderPresenter: React.FC = () => (
  <header className={styles.header}>
    <Link href="/login">
      <Img className={styles.logo} alt="logo" src="/logo.png" />
    </Link>
  </header>
);
