/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GroupMedicalInstitution,
    GroupMedicalInstitutionFromJSON,
    GroupMedicalInstitutionFromJSONTyped,
    GroupMedicalInstitutionToJSON,
} from './GroupMedicalInstitution';

/**
 * テナント情報
 * @export
 * @interface CurrentUserTenant
 */
export interface CurrentUserTenant {
    /**
     * テナントID
     * @type {number}
     * @memberof CurrentUserTenant
     */
    id: number;
    /**
     * テナント名
     * @type {string}
     * @memberof CurrentUserTenant
     */
    name: string;
    /**
     * ロゴURL
     * @type {string}
     * @memberof CurrentUserTenant
     */
    logo: string;
    /**
     * テナントの医療機関ID
     * @type {number}
     * @memberof CurrentUserTenant
     */
    medicalInstitutionId: number;
    /**
     * 属している都道府県
     * @type {string}
     * @memberof CurrentUserTenant
     */
    prefecture: string;
    /**
     * 緯度
     * @type {number}
     * @memberof CurrentUserTenant
     */
    longitude: number;
    /**
     * 経度
     * @type {number}
     * @memberof CurrentUserTenant
     */
    latitude: number;
    /**
     * デモテナントの場合true
     * @type {boolean}
     * @memberof CurrentUserTenant
     */
    demo: boolean;
    /**
     * 契約プラン
     * @type {string}
     * @memberof CurrentUserTenant
     */
    plan: CurrentUserTenantPlanEnum;
    /**
     * 最大アカウント登録可能数
     * @type {number}
     * @memberof CurrentUserTenant
     */
    maxAccountCount: number | null;
    /**
     * 最大タグ登録可能数
     * @type {number}
     * @memberof CurrentUserTenant
     */
    maxTagCount: number | null;
    /**
     * グループ病院
     * @type {Array<GroupMedicalInstitution>}
     * @memberof CurrentUserTenant
     */
    groupMedicalInstitutions: Array<GroupMedicalInstitution> | null;
}

/**
* @export
* @enum {string}
*/
export enum CurrentUserTenantPlanEnum {
    Standard202211 = 'standard202211',
    Early = 'early',
    Starter = 'starter',
    Basic = 'basic',
    Growth = 'growth',
    Beginner202112 = 'beginner202112',
    Growth202112 = 'growth202112',
    Professional202112 = 'professional202112'
}

export function CurrentUserTenantFromJSON(json: any): CurrentUserTenant {
    return CurrentUserTenantFromJSONTyped(json, false);
}

export function CurrentUserTenantFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentUserTenant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'logo': json['logo'],
        'medicalInstitutionId': json['medical_institution_id'],
        'prefecture': json['prefecture'],
        'longitude': json['longitude'],
        'latitude': json['latitude'],
        'demo': json['demo'],
        'plan': json['plan'],
        'maxAccountCount': json['max_account_count'],
        'maxTagCount': json['max_tag_count'],
        'groupMedicalInstitutions': (json['group_medical_institutions'] === null ? null : (json['group_medical_institutions'] as Array<any>).map(GroupMedicalInstitutionFromJSON)),
    };
}

export function CurrentUserTenantToJSON(value?: CurrentUserTenant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'logo': value.logo,
        'medical_institution_id': value.medicalInstitutionId,
        'prefecture': value.prefecture,
        'longitude': value.longitude,
        'latitude': value.latitude,
        'demo': value.demo,
        'plan': value.plan,
        'max_account_count': value.maxAccountCount,
        'max_tag_count': value.maxTagCount,
        'group_medical_institutions': (value.groupMedicalInstitutions === null ? null : (value.groupMedicalInstitutions as Array<any>).map(GroupMedicalInstitutionToJSON)),
    };
}

