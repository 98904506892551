/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutAccountRequest
 */
export interface PutAccountRequest {
    /**
     * ユーザー名
     * @type {string}
     * @memberof PutAccountRequest
     */
    username: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PutAccountRequest
     */
    email: string | null;
    /**
     * ログインID
     * @type {string}
     * @memberof PutAccountRequest
     */
    loginName: string | null;
    /**
     * 役割
     * @type {string}
     * @memberof PutAccountRequest
     */
    role: PutAccountRequestRoleEnum;
}

/**
* @export
* @enum {string}
*/
export enum PutAccountRequestRoleEnum {
    Member = 'member',
    Owner = 'owner'
}

export function PutAccountRequestFromJSON(json: any): PutAccountRequest {
    return PutAccountRequestFromJSONTyped(json, false);
}

export function PutAccountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutAccountRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'email': json['email'],
        'loginName': json['login_name'],
        'role': json['role'],
    };
}

export function PutAccountRequestToJSON(value?: PutAccountRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'email': value.email,
        'login_name': value.loginName,
        'role': value.role,
    };
}

