/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 1紹介に対する入院(手術含む)の詳細
 * @export
 * @interface HospitalAdmission
 */
export interface HospitalAdmission {
    /**
     * 入院日
     * @type {string}
     * @memberof HospitalAdmission
     */
    admissionStartedOn: string | null;
    /**
     * 入院診療科(厚労省様式診療科/病院ごとに拡張された診療科)
     * @type {string}
     * @memberof HospitalAdmission
     */
    admissionDepartment: string | null;
    /**
     * 主傷病コード
     * @type {string}
     * @memberof HospitalAdmission
     */
    icd10MainDiseaseCode: string | null;
    /**
     * 手術コード
     * @type {string}
     * @memberof HospitalAdmission
     */
    kcodeOperation1: string | null;
}

export function HospitalAdmissionFromJSON(json: any): HospitalAdmission {
    return HospitalAdmissionFromJSONTyped(json, false);
}

export function HospitalAdmissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): HospitalAdmission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'admissionStartedOn': json['admission_started_on'],
        'admissionDepartment': json['admission_department'],
        'icd10MainDiseaseCode': json['icd10_main_disease_code'],
        'kcodeOperation1': json['kcode_operation1'],
    };
}

export function HospitalAdmissionToJSON(value?: HospitalAdmission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'admission_started_on': value.admissionStartedOn,
        'admission_department': value.admissionDepartment,
        'icd10_main_disease_code': value.icd10MainDiseaseCode,
        'kcode_operation1': value.kcodeOperation1,
    };
}

