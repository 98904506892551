/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReferralFile,
    ReferralFileFromJSON,
    ReferralFileFromJSONTyped,
    ReferralFileToJSON,
} from './ReferralFile';

/**
 * 
 * @export
 * @interface GetUploadsReferralFilesResponse
 */
export interface GetUploadsReferralFilesResponse {
    /**
     * 一覧の総ページ数
     * @type {number}
     * @memberof GetUploadsReferralFilesResponse
     */
    totalPage: number;
    /**
     * 一覧の総要素数
     * @type {number}
     * @memberof GetUploadsReferralFilesResponse
     */
    totalCount: number;
    /**
     * 紹介データファイルの一覧
     * @type {Array<ReferralFile>}
     * @memberof GetUploadsReferralFilesResponse
     */
    referralFiles: Array<ReferralFile>;
}

export function GetUploadsReferralFilesResponseFromJSON(json: any): GetUploadsReferralFilesResponse {
    return GetUploadsReferralFilesResponseFromJSONTyped(json, false);
}

export function GetUploadsReferralFilesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUploadsReferralFilesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPage': json['total_page'],
        'totalCount': json['total_count'],
        'referralFiles': ((json['referral_files'] as Array<any>).map(ReferralFileFromJSON)),
    };
}

export function GetUploadsReferralFilesResponseToJSON(value?: GetUploadsReferralFilesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_page': value.totalPage,
        'total_count': value.totalCount,
        'referral_files': ((value.referralFiles as Array<any>).map(ReferralFileToJSON)),
    };
}

