/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReferralFile
 */
export interface ReferralFile {
    /**
     * ファイルID
     * @type {number}
     * @memberof ReferralFile
     */
    id: number;
    /**
     * 作業ステータス
     * @type {string}
     * @memberof ReferralFile
     */
    status: ReferralFileStatusEnum;
    /**
     * アップロード日
     * @type {string}
     * @memberof ReferralFile
     */
    uploadedDate: string;
    /**
     * アップロードした紹介データファイル名
     * @type {string}
     * @memberof ReferralFile
     */
    filename: string;
    /**
     * 担当者
     * @type {string}
     * @memberof ReferralFile
     */
    username: string;
}

/**
* @export
* @enum {string}
*/
export enum ReferralFileStatusEnum {
    Waiting = 'waiting',
    Working = 'working',
    Completed = 'completed',
    Failed = 'failed'
}

export function ReferralFileFromJSON(json: any): ReferralFile {
    return ReferralFileFromJSONTyped(json, false);
}

export function ReferralFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': json['status'],
        'uploadedDate': json['uploaded_date'],
        'filename': json['filename'],
        'username': json['username'],
    };
}

export function ReferralFileToJSON(value?: ReferralFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': value.status,
        'uploaded_date': value.uploadedDate,
        'filename': value.filename,
        'username': value.username,
    };
}

