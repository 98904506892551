/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    GetUploadsReferralFilesResponse,
    GetUploadsReferralFilesResponseFromJSON,
    GetUploadsReferralFilesResponseToJSON,
    ReferralFile,
    ReferralFileFromJSON,
    ReferralFileToJSON,
} from '../models';

export interface GetUploadsReferralFilesRequest {
    page?: number;
}

export interface PostUploadsReferralFileOperationRequest {
    file: Blob;
}

/**
 * UploadsApi - interface
 * 
 * @export
 * @interface UploadsApiInterface
 */
export interface UploadsApiInterface {
    /**
     * 過去にアップロードされている紹介データの一覧取得
     * @summary アップロードした紹介データファイルの一覧取得
     * @param {number} [page] ページ番号（１始まり）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApiInterface
     */
    getUploadsReferralFilesRaw(requestParameters: GetUploadsReferralFilesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetUploadsReferralFilesResponse>>;

    /**
     * 過去にアップロードされている紹介データの一覧取得
     * アップロードした紹介データファイルの一覧取得
     */
    getUploadsReferralFiles(requestParameters: GetUploadsReferralFilesRequest, initOverrides?: RequestInit): Promise<GetUploadsReferralFilesResponse>;

    /**
     * 対応ファイルフォーマット (csv)
     * @summary 紹介データファイルのアップロード
     * @param {Blob} file アップロードするファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApiInterface
     */
    postUploadsReferralFileOperationRaw(requestParameters: PostUploadsReferralFileOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReferralFile>>;

    /**
     * 対応ファイルフォーマット (csv)
     * 紹介データファイルのアップロード
     */
    postUploadsReferralFileOperation(requestParameters: PostUploadsReferralFileOperationRequest, initOverrides?: RequestInit): Promise<ReferralFile>;

}

/**
 * 
 */
export class UploadsApi extends runtime.BaseAPI implements UploadsApiInterface {

    /**
     * 過去にアップロードされている紹介データの一覧取得
     * アップロードした紹介データファイルの一覧取得
     */
    async getUploadsReferralFilesRaw(requestParameters: GetUploadsReferralFilesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetUploadsReferralFilesResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/uploads/referral_files`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUploadsReferralFilesResponseFromJSON(jsonValue));
    }

    /**
     * 過去にアップロードされている紹介データの一覧取得
     * アップロードした紹介データファイルの一覧取得
     */
    async getUploadsReferralFiles(requestParameters: GetUploadsReferralFilesRequest = {}, initOverrides?: RequestInit): Promise<GetUploadsReferralFilesResponse> {
        const response = await this.getUploadsReferralFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 対応ファイルフォーマット (csv)
     * 紹介データファイルのアップロード
     */
    async postUploadsReferralFileOperationRaw(requestParameters: PostUploadsReferralFileOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReferralFile>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling postUploadsReferralFileOperation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/uploads/referral_files`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralFileFromJSON(jsonValue));
    }

    /**
     * 対応ファイルフォーマット (csv)
     * 紹介データファイルのアップロード
     */
    async postUploadsReferralFileOperation(requestParameters: PostUploadsReferralFileOperationRequest, initOverrides?: RequestInit): Promise<ReferralFile> {
        const response = await this.postUploadsReferralFileOperationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
