/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MedicalInstitutionProfile
 */
export interface MedicalInstitutionProfile {
    /**
     * 医療機関種類
     * @type {Array<string>}
     * @memberof MedicalInstitutionProfile
     */
    typeNames: Array<string>;
    /**
     * 住所
     * @type {string}
     * @memberof MedicalInstitutionProfile
     */
    address: string;
    /**
     * 管理者名
     * @type {string}
     * @memberof MedicalInstitutionProfile
     */
    administratorName: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof MedicalInstitutionProfile
     */
    phoneNumber: string | null;
    /**
     * FAX番号
     * @type {string}
     * @memberof MedicalInstitutionProfile
     */
    faxNumber: string | null;
    /**
     * 公式サイトのURL
     * @type {string}
     * @memberof MedicalInstitutionProfile
     */
    officialSiteUrl: string | null;
    /**
     * 診療科目
     * @type {Array<string>}
     * @memberof MedicalInstitutionProfile
     */
    departments: Array<string>;
}

export function MedicalInstitutionProfileFromJSON(json: any): MedicalInstitutionProfile {
    return MedicalInstitutionProfileFromJSONTyped(json, false);
}

export function MedicalInstitutionProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'typeNames': json['type_names'],
        'address': json['address'],
        'administratorName': json['administrator_name'],
        'phoneNumber': json['phone_number'],
        'faxNumber': json['fax_number'],
        'officialSiteUrl': json['official_site_url'],
        'departments': json['departments'],
    };
}

export function MedicalInstitutionProfileToJSON(value?: MedicalInstitutionProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type_names': value.typeNames,
        'address': value.address,
        'administrator_name': value.administratorName,
        'phone_number': value.phoneNumber,
        'fax_number': value.faxNumber,
        'official_site_url': value.officialSiteUrl,
        'departments': value.departments,
    };
}

