/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 逆紹介の詳細
 * @export
 * @interface ReverseReferralDetail
 */
export interface ReverseReferralDetail {
    /**
     * 逆紹介日
     * @type {string}
     * @memberof ReverseReferralDetail
     */
    reverseReferralDate: string;
    /**
     * 患者番号
     * @type {string}
     * @memberof ReverseReferralDetail
     */
    patientId: string | null;
    /**
     * 診療科
     * @type {string}
     * @memberof ReverseReferralDetail
     */
    reverseReferralDepartment: string | null;
}

export function ReverseReferralDetailFromJSON(json: any): ReverseReferralDetail {
    return ReverseReferralDetailFromJSONTyped(json, false);
}

export function ReverseReferralDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReverseReferralDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reverseReferralDate': json['reverse_referral_date'],
        'patientId': json['patient_id'],
        'reverseReferralDepartment': json['reverse_referral_department'],
    };
}

export function ReverseReferralDetailToJSON(value?: ReverseReferralDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reverse_referral_date': value.reverseReferralDate,
        'patient_id': value.patientId,
        'reverse_referral_department': value.reverseReferralDepartment,
    };
}

