/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * アカウントID
     * @type {number}
     * @memberof Account
     */
    id: number;
    /**
     * ユーザー名
     * @type {string}
     * @memberof Account
     */
    username: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof Account
     */
    email: string | null;
    /**
     * ログインID
     * @type {string}
     * @memberof Account
     */
    loginName: string | null;
    /**
     * 役割
     * @type {string}
     * @memberof Account
     */
    role: AccountRoleEnum;
    /**
     * 最終ログイン日
     * @type {string}
     * @memberof Account
     */
    lastLoginDate: string | null;
    /**
     * 認証済の場合は true
     * @type {boolean}
     * @memberof Account
     */
    isConfirmed: boolean;
}

/**
* @export
* @enum {string}
*/
export enum AccountRoleEnum {
    Member = 'member',
    Owner = 'owner'
}

export function AccountFromJSON(json: any): Account {
    return AccountFromJSONTyped(json, false);
}

export function AccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): Account {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'email': json['email'],
        'loginName': json['login_name'],
        'role': json['role'],
        'lastLoginDate': json['last_login_date'],
        'isConfirmed': json['is_confirmed'],
    };
}

export function AccountToJSON(value?: Account | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'username': value.username,
        'email': value.email,
        'login_name': value.loginName,
        'role': value.role,
        'last_login_date': value.lastLoginDate,
        'is_confirmed': value.isConfirmed,
    };
}

