/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HospitalAdmission,
    HospitalAdmissionFromJSON,
    HospitalAdmissionFromJSONTyped,
    HospitalAdmissionToJSON,
} from './HospitalAdmission';

/**
 * 紹介の詳細
 * @export
 * @interface ReferralDetail
 */
export interface ReferralDetail {
    /**
     * 紹介日
     * @type {string}
     * @memberof ReferralDetail
     */
    referralDate: string;
    /**
     * 患者番号
     * @type {string}
     * @memberof ReferralDetail
     */
    patientId: string | null;
    /**
     * 来院診療科
     * @type {string}
     * @memberof ReferralDetail
     */
    referralDepartment: string;
    /**
     * 1紹介に対する入院(手術含む)の一覧
     * @type {Array<HospitalAdmission>}
     * @memberof ReferralDetail
     */
    hospitalAdmissions: Array<HospitalAdmission>;
}

export function ReferralDetailFromJSON(json: any): ReferralDetail {
    return ReferralDetailFromJSONTyped(json, false);
}

export function ReferralDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referralDate': json['referral_date'],
        'patientId': json['patient_id'],
        'referralDepartment': json['referral_department'],
        'hospitalAdmissions': ((json['hospital_admissions'] as Array<any>).map(HospitalAdmissionFromJSON)),
    };
}

export function ReferralDetailToJSON(value?: ReferralDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referral_date': value.referralDate,
        'patient_id': value.patientId,
        'referral_department': value.referralDepartment,
        'hospital_admissions': ((value.hospitalAdmissions as Array<any>).map(HospitalAdmissionToJSON)),
    };
}

