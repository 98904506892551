/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostAccountRequest
 */
export interface PostAccountRequest {
    /**
     * ユーザ名
     * @type {string}
     * @memberof PostAccountRequest
     */
    username: string;
    /**
     * メールアドレスまたはログインID
     * @type {string}
     * @memberof PostAccountRequest
     */
    emailOrLoginName: string;
    /**
     * ログイン方法
     * @type {string}
     * @memberof PostAccountRequest
     */
    loginMethod: PostAccountRequestLoginMethodEnum;
    /**
     * 役割
     * @type {string}
     * @memberof PostAccountRequest
     */
    role?: PostAccountRequestRoleEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostAccountRequestLoginMethodEnum {
    Email = 'email',
    LoginName = 'login_name'
}/**
* @export
* @enum {string}
*/
export enum PostAccountRequestRoleEnum {
    Member = 'member',
    Owner = 'owner'
}

export function PostAccountRequestFromJSON(json: any): PostAccountRequest {
    return PostAccountRequestFromJSONTyped(json, false);
}

export function PostAccountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostAccountRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'emailOrLoginName': json['email_or_login_name'],
        'loginMethod': json['login_method'],
        'role': !exists(json, 'role') ? undefined : json['role'],
    };
}

export function PostAccountRequestToJSON(value?: PostAccountRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'email_or_login_name': value.emailOrLoginName,
        'login_method': value.loginMethod,
        'role': value.role,
    };
}

