import { sessionsApi } from '@/src/api';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { useAccount } from '@/src/hooks/useAccount';

import { msalInstance } from './config';

import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import * as Sentry from '@sentry/browser';
import { useRouter } from 'next/router';
import { useRef, useEffect } from 'react';

const InnerAuth: React.FC<{ children: React.ReactNode }> = (props) => {
  const accountManage = useAccount();
  const isChecked = useRef(true);
  const throwFetchError = useFetchError();

  useEffect(() => {
    // マウント時のみ、currentUserを取得する
    (async () => {
      isChecked.current = false;

      const account = await sessionsApi
        .getCurrentUser()
        .then((user) => ({
          ...user,
          name: user.username ?? '',
          emailSenderAllowedAddresses: user.emailSenderAllowedAddresses ?? [],
        }))
        .catch(() => throwFetchError(401));

      if (account) {
        accountManage.setAccount(account);

        if (account.id !== null && account.tenant !== null) {
          // current_user取得後にsentryに情報をアカウント情報を格納する
          Sentry.setUser({
            id: account.id.toString(),
            username: account.name,
            segment: account.tenant.id.toString(),
            tenant_id: account.tenant.id,
          });
          Sentry.setTag('tenant_id', account.tenant.id);
          Sentry.setTag('tenant_name', account.tenant.name);
        }
      } else {
        accountManage.clearAccount();
      }

      isChecked.current = true;
    })();
  }, []);

  // NOTE: current_userの結果を待たないと描画できない画面があるため利用できるようになるのを待つ
  if (accountManage.account.id === null) {
    return null;
  }

  return <>{props.children}</>;
};

export const Auth: React.FC<{ children: React.ReactNode }> = (props) => {
  const router = useRouter();

  if (!router.isReady) {
    return null;
  }

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <InnerAuth>{props.children}</InnerAuth>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};
