import { FetchError } from '@/src/error/fetchError/FetchError';
import { errorCapture } from '@/src/utils/errorCapture';

import { Error } from '../components/features/Error';
import { msalInstance } from './Auth/config';

import { Component } from 'react';

type Props = {
  children: React.ReactNode;
};

type State = {
  error?: Error | FetchError;
};

class ErrorBoundary extends Component<Props, State> {
  state: State = {};

  static getDerivedStateFromError(error: Error | FetchError): State {
    if (error instanceof FetchError) {
      if (error.statusCode === 401) {
        msalInstance.logoutRedirect();
        return {};
      }
    }
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    errorCapture(error, errorInfo);
  }

  render(): React.ReactNode {
    if (this.state.error != null) {
      if (this.state.error instanceof FetchError) {
        return <Error fetchError={this.state.error} />;
      }

      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
